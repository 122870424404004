<template>
  <v-container fluid class="px-8">
    <v-toolbar height="50" class="mb-4 mt-2" elevation="4" rounded>
      <TableMenu />
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="6">
        <special-indicators
          @formChanges="(value) => controlNavigation(value, 'specialIndicators')"
        ></special-indicators>
      </v-col>

      <v-col cols="12" md="6">
        <special-travel-needs @formChanges="(value) => controlNavigation(value, 'travelNeeds')"></special-travel-needs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SpecialIndicators from './SpecialIndicators.vue';
import SpecialTravelNeeds from './SpecialTravelNeeds.vue';
import TableMenu from './Menu.vue';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { isEqual } from 'lodash';

export default {
  mixins: [promptBeforeLeave],
  components: { SpecialIndicators, SpecialTravelNeeds, TableMenu },
  data: () => ({
    formsChanged: {
      specialIndicators: false,
      travelNeeds: false,
    },
  }),
  watch: {
    formsChanged: {
      handler(value) {
        this.baseFormValue = { success: true };
        if (Object.values(value).some((changed) => changed)) this.latestFormValue = { success: false };
        else this.latestFormValue = { success: true };
      },
      deep: true,
    },
  },
  methods: {
    controlNavigation(value, formName) {
      const baseForm = value.baseFormValue ?? null;
      const latestForm = value.latestFormValue ?? null;
      this.formsChanged[formName] = !isEqual(baseForm, latestForm);
    },
  },
};
</script>

<style scoped></style>
