<template>
  <v-card>
    <v-card-title>
      Special Indicators
      <v-spacer></v-spacer>
      <v-btn class="mx-2" fab small color="primary" @click="addSI()">
        <v-icon v-if="!editedSI && canEditSetupTables">mdi-plus</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-form>
      <v-row dense>
        <v-col cols="12" md="12">
          <v-scroll-x-transition>
            <v-btn v-if="canEditSetupTables" v-show="selected.length" class="mx-2" dark color="red" @click="deleteSI()">
              <v-icon>mdi-delete</v-icon>Delete {{ selected.length }} Special Indicator{{
                selected.length > 1 ? 's' : ''
              }}
            </v-btn>
          </v-scroll-x-transition>
        </v-col>
      </v-row>
      <v-row dense v-if="editedSI">
        <v-col cols="12" md="12">
          <v-container>
            <v-card>
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      label="Indicator"
                      :value="editedSI.name"
                      v-model="$v.form.name.$model"
                      required
                      :error-messages="handleErrors($v.form.name)"
                      @blur="$v.form.name.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-btn color="green" dark @click="save"> save </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="12">
          <v-data-table
            @click:row="editSI($event)"
            fixed-header
            :headers="headers"
            :items="specialIndicators"
            item-key="id"
            show-select
            v-model="selected"
          >
            <template v-if="!canEditSetupTables" v-slot:[`header.data-table-select`]></template>
            <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
              <v-simple-checkbox
                :value="isSelected"
                @input="select($event)"
                v-ripple
                :disabled="!canEditSetupTables"
              ></v-simple-checkbox>
            </template>
            <template #[`item.tripTypes`]="{ item }">
              <p class="mb-0">{{ item.tripTypeIds.map((e) => tripTypesById[e].name).join(', ') }}</p>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
import { mapActions, mapGetters } from 'vuex';
import {
  GET_SPECIAL_INDICATORS,
  SAVE_SPECIAL_INDICATOR,
  DELETE_SPECIAL_INDICATORS,
} from '@/store/modules/Config/actions';
import { cloneDeep } from 'lodash';

export default {
  mixins: [validationMixin],
  components: {},
  data: () => ({
    handleErrors,
    specialIndicators: [],
    id: null,
    form: { id: 0, name: '' },
    headers: [{ text: 'Special Indicator', value: 'name' }],
    editedSI: null,
    selected: [],
    baseFormValue: null,
  }),
  validations: {
    form: {
      name: { required },
    },
  },
  async mounted() {
    await this.fetchItems();
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
  },
  methods: {
    ...mapActions('config', [GET_SPECIAL_INDICATORS, SAVE_SPECIAL_INDICATOR, DELETE_SPECIAL_INDICATORS]),
    async fetchItems() {
      this.specialIndicators = await this.getSpecialIndicators();
    },
    clear() {
      this.form = { id: 0, name: '' };
      this.$refs.aform.clear();
    },
    addSI() {
      if (!this.editedSI) {
        this.editedSI = { id: 0, name: '' };
        this.baseFormValue = cloneDeep(this.editedSI);
      } else this.editedSI = null;
    },
    editSI(si) {
      if (this.canEditSetupTables) {
        this.editedSI = si;
        this.baseFormValue = cloneDeep(this.editedSI);
      }
    },
    async save() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      try {
        const r = await this.saveSpecialIndicator(this.$v.form.$model);
        if (r && r.id) {
          this.$myalert.success('Special Indicator saved');
          this.editedSI = null;
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.description);
      }
    },
    async deleteSI() {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          this.selected.length > 1 ? 'these Special Indicators' : 'this Special Indicator'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteSpecialIndicators(this.selected.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${this.selected.length > 1 ? 'Special Indicators' : 'Special Indicator'} deleted`);
          this.editedSI = null;
          this.selected = [];
          this.fetchItems();
        }
      }
    },
  },
  watch: {
    editedSI: {
      immediate: true,
      deep: true,
      handler: function (editedSI) {
        if (editedSI === null) {
          this.baseFormValue = cloneDeep({ ...{ id: 0, name: '' }, ...editedSI });
        }
        this.form = { ...{ id: 0, name: '' }, ...editedSI };
      },
    },
    form: {
      deep: true,
      handler: function (value) {
        this.$emit('formChanges', {
          latestFormValue: cloneDeep(value),
          baseFormValue: cloneDeep(this.baseFormValue),
        });
      },
    },
  },
};
</script>

<style scoped></style>
