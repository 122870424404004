<template>
  <v-card>
    <v-card-title>
      Special Travel Needs
      <v-spacer></v-spacer>
      <v-btn class="mx-2" fab small color="primary" @click="addSTN()">
        <v-icon v-if="!editedSTN && (me.is.superAdmin || me.is.transportationAdmin)">mdi-plus</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <form>
      <v-row dense>
        <v-col cols="12" md="12">
          <v-scroll-x-transition>
            <v-btn
              v-if="me.is.superAdmin || me.is.transportationAdmin"
              v-show="selected.length"
              class="mx-2"
              dark
              color="red"
              @click="deleteSTN()"
            >
              <v-icon>mdi-delete</v-icon>Delete {{ selected.length }} Special Travel Need{{
                selected.length > 1 ? 's' : ''
              }}
            </v-btn>
          </v-scroll-x-transition>
        </v-col>
      </v-row>
      <v-row dense v-if="editedSTN">
        <v-col cols="12" md="12">
          <v-container>
            <v-card>
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      label="Indicator"
                      :value="editedSTN.name"
                      v-model="$v.form.name.$model"
                      required
                      :error-messages="handleErrors($v.form.name)"
                      @blur="$v.form.name.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-btn color="green" dark @click="save"> save </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="12">
          <v-data-table
            @click:row="editSTN($event)"
            fixed-header
            :headers="headers"
            :items="specialTravelNeeds"
            item-key="id"
            show-select
            v-model="selected"
          >
            <template
              v-if="!me.is.superAdmin && !me.is.transportationAdmin"
              v-slot:[`header.data-table-select`]
            ></template>
            <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
              <v-simple-checkbox
                :value="isSelected"
                @input="select($event)"
                v-ripple
                :disabled="!me.is.superAdmin && !me.is.transportationAdmin"
              ></v-simple-checkbox>
            </template>
            <template #[`item.tripTypes`]="{ item }">
              <p class="mb-0">{{ item.tripTypeIds.map((e) => tripTypesById[e].name).join(', ') }}</p>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </form>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
import { mapActions, mapGetters } from 'vuex';
import {
  GET_SPECIAL_TRAVEL_NEEDS,
  SAVE_SPECIAL_TRAVEL_NEED,
  DELETE_SPECIAL_TRAVEL_NEEDS,
} from '@/store/modules/Config/actions';
import { cloneDeep } from 'lodash';

export default {
  mixins: [validationMixin],
  components: {},
  data: () => ({
    handleErrors,
    specialTravelNeeds: [],
    id: null,
    form: { id: 0, name: '' },
    headers: [{ text: 'Special Travel Need', value: 'name' }],
    editedSTN: null,
    selected: [],
    baseFormValue: null,
  }),
  validations: {
    form: {
      name: { required },
    },
  },
  async mounted() {
    await this.fetchItems();
  },
  computed: {
    ...mapGetters('user', ['me']),
  },
  methods: {
    ...mapActions('config', [GET_SPECIAL_TRAVEL_NEEDS, SAVE_SPECIAL_TRAVEL_NEED, DELETE_SPECIAL_TRAVEL_NEEDS]),
    async fetchItems() {
      this.specialTravelNeeds = await this.getSpecialTravelNeeds();
    },
    clear() {
      this.form = { id: 0, name: '' };
      this.$refs.aform.clear();
    },
    addSTN() {
      if (!this.editedSTN) {
        this.editedSTN = { id: 0, name: '' };
        this.baseFormValue = cloneDeep(this.editedSTN);
      } else this.editedSTN = null;
    },
    editSTN(stn) {
      if (this.me.is.superAdmin || this.me.is.transportationAdmin) {
        this.editedSTN = stn;
        this.baseFormValue = cloneDeep(this.editedSTN);
      }
    },
    async save() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      try {
        const r = await this.saveSpecialTravelNeed(this.$v.form.$model);
        if (r && r.id) {
          this.$myalert.success('Special Travel Need saved');
          this.editedSTN = null;
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.description);
      }
    },
    async deleteSTN() {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          this.selected.length > 1 ? 'these Special Travel Needs' : 'this Special Travel Need'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteSpecialTravelNeeds(this.selected.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${this.selected.length > 1 ? 'Special Travel Needs' : 'Special Travel Need'} deleted`);
          this.editedSTN = null;
          this.selected = [];
          this.fetchItems();
        }
      }
    },
  },
  watch: {
    editedSTN: {
      immediate: true,
      deep: true,
      handler: function (editedSTN) {
        if (editedSTN === null) {
          this.baseFormValue = cloneDeep({ ...{ id: 0, name: '' }, ...editedSTN });
        }
        this.form = { ...{ id: 0, name: '' }, ...editedSTN };
      },
    },
    form: {
      deep: true,
      handler: function (value) {
        this.$emit('formChanges', {
          latestFormValue: cloneDeep(value),
          baseFormValue: cloneDeep(this.baseFormValue),
        });
      },
    },
  },
};
</script>

<style scoped></style>
