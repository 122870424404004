var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-8", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mb-4 mt-2",
          attrs: { height: "50", elevation: "4", rounded: "" },
        },
        [_c("TableMenu")],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("special-indicators", {
                on: {
                  formChanges: (value) =>
                    _vm.controlNavigation(value, "specialIndicators"),
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("special-travel-needs", {
                on: {
                  formChanges: (value) =>
                    _vm.controlNavigation(value, "travelNeeds"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }